<template>
  <div class="home_ranking">
    <div class="home_ranking_box">
      <div class="header">
        <div class="header_title">消息通知</div>
        <div class="header_right" @click="goBack">
          <div class="right_ic">
            <img src="@/assets/images/homeImg/arrow_left.png"
                  class="qsy_img" alt="">
          </div>
          <div class="right_back">返回</div>
        </div>
      </div>
      <div class="news_content">
        <div class="news_content_title">{{data.title}}</div>
        <div class="news_content_date">{{data.updateTime}}</div>
        <div v-html="data.content" class="news_content_text"></div>
      </div>
    </div>
 </div>
</template>
 
<script>
import { newsDetails } from '@/api/news'
export default {

  data() {
    return {
      data: {},
      id: ''
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    getData() {
      newsDetails({
        id: this.id
      }).then(res => {
        this.data = res.result
      })
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>
 
<style lang = "scss" scoped>
.home_ranking {
  width: 1200px;
  margin: 48px auto 192px;
  .home_ranking_box {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 24px;
    background: #ffffff;
    .header{
      width: 1154px;
      height: 72px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      background: #F5F5F5;
      border-radius: 10px;
      .header_title{
        flex: 1;
        margin-left: 49px;
        font-size: 24px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        color: #989898;
      }
      .header_right{
        display: flex;
        align-items: center;
        margin-right: 49px;
        cursor: pointer;
        .right_ic{
          width: 15px;
          height: 26px;
          margin-right: 14px;
        }
        .right_back{
          font-size: 24px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          color: #989898;
        }
      }
    }
    .news_content{
      padding: 0 49px 200px;
      .news_content_title{
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #989898;
        line-height: 24px;
      }
      .news_content_date{
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #989898;
        margin: 20px 0 10px;
      }
      .news_content_text{
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #989898;
        line-height: 36px;
      }
    }
  }
}

.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>