import axios from '@/utils/request'


// 获取消息列表
export const getList = (data) => {
  return axios.request({
    url: '/api/message/list',
    data,
    method: 'get'
  })
}

// 获取消息详情
export const newsDetails = (data) => {
  return axios.request({
    url: '/api/message/queryById',
    data,
    method: 'get'
  })
}

// 记录消息阅读记录
export const newsRecord = (data) => {
  return axios.request({
    url: '/api/messageUser/add',
    data,
    method: 'post'
  })
}

// 获取训练管理的数据
export const getTrainData = (data) => {
  return axios.request({
    url: '/api/userLearningTask/underling/taskStudy',
    data,
    method: 'get'
  })
}
export const getMessageList = (data) => {
  return axios.request({
    url: '/api/messageUser/getUnreadInfo',
    data,
    method: 'get'
  })
}